import React from 'react'
import { Container } from 'react-bootstrap'
import './navigation.module.css'
// import 'bootstrap/dist/css/bootstrap.min.css';


import Navigation from './navigation'

class Template extends React.Component {
  render() {
    const { location, children } = this.props
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <Container fluid className="p-0">
        <Navigation />
        {children}
      </Container>
    )
  }
}

export default Template
