import React from 'react'
import { Container, Row, Card } from 'react-bootstrap'
import 'lazysizes';
import style from './IconBlock.module.css'
import one from '../images/1.svg'
import two from '../images/3.svg'


export default ({ data, isVisible  }) => (
  <Row className={'m-0 p-0'}>    
    <Container>
      {data.position && 
        <Row className={`py-5 px-md-2 px-lg-0 no-animation ` + (isVisible ? 'scale-in-center' : 'scale-out-center')}>
          <Card className="border-0 px-0 m-0">
            <Row className="px-3 m-0">
              <div className={`col-8 col-md-3 col-lg-2 offset-2 offset-md-0 px-0 align-self-center`}>
                {/* <img className="lazyload" data-src={data.media?.file?.url} alt={data.media?.title} /> */}
                <object className="lazyload" data={data.media?.file?.url} alt={data.media?.title}/>
              </div>
              <div className="col-10 col-md-8 col-lg-9 offset-1 ">
                <Card.Body className="px-0">
                  <Card.Title className={`${style.title} pt-0 pt-lg-3 text-center text-md-left`}>{data.iconBlockTitle?.internal?.content}</Card.Title>
                  <Card.Text className={`${style.text} text-center text-md-left px-0`}>
                    {data.description?.internal?.content}
                  </Card.Text>
                </Card.Body>
              </div>
            </Row>
          </Card>
        </Row>
      }
          {!data.position &&
        <Row className={`pt-0 py-md-5 px-md-2 px-lg-0 no-animation `  + (isVisible ? 'scale-in-center' : 'scale-out-center')}>
          <Card className="border-0 pb-4 pb-md-0 m-0">
            <Row className="px-2 px-md-0 m-0">
              <div className="col-10 col-md-8 col-lg-9 offset-1 offset-md-0 pl-0 order-md-first order-last">
                <Card.Body className="pr-0 pl-md-2 ">
                  <Card.Title className={`${style.title} pt-0 pt-lg-3 text-center text-md-left`}>{data.iconBlockTitle?.internal?.content}</Card.Title>
                  <Card.Text className={`${style.text} text-center text-md-left`}>
                    {data.description?.internal?.content}
                  </Card.Text>
                </Card.Body>
              </div>
              <div className={`${style.imageContainer} align-self-center col-8 col-md-3 col-lg-2 offset-2 offset-md-1 px-0 justify-content-end`}>
                {/* <img className="lazyload" data-src={data.media?.file?.url} alt={data.media?.title} /> */}
                <object className="lazyload" data={data.media?.file?.url}  alt={data.media?.title}/>
              </div>

            </Row>
          </Card>
        </Row>
      }
    
    </Container >
  </Row>
)
