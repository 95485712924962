import React from 'react'
import { Container, Row, Card } from 'react-bootstrap'
import 'lazysizes';
import style from './ImageBlock.module.css'


export default ({ data, isVisible  }) => (
  <Row className='m-0 p-0'>
    <Container>      
      {data.position &&
        <Row className={`${style.block} pt-3 pt-lg-4 no-animation ` + (isVisible ? 'scale-in-center' : 'scale-out-center')}>
        {/* <Row className={`${style.block} pt-3 pt-lg-4 no-animation ` + (isVisible ? 'animate-visible-left' : ' animate-visible-left-out ')}> */}
          <Card className="border-0 rounded-0 ">
            <Row className="pl-2 m-0">
              <div className={`${style.imageContainer} col-12 col-md-5 px-0 align-self-center`}>
                <Card.Img className={`${style.action} rounded-0 lazyload`} alt={data.image?.title} data-src={data.image?.file?.url} />
              </div>
              <div className="offset-md-0 offset-1 col-10 col-md-7 col-lg-6 align-self-center px-0">
                <Card.Body className={`${style.textBlock} px-0`}>
                  <Card.Text className={`${style.textLeft}`}>
                    {data.imageBlockDescription}
                  </Card.Text>
                </Card.Body>
              </div>
            </Row>
          </Card>
        </Row>
      }
      {!data.position &&
        <Row className={`pb-5 mt-1 no-animation ` + (isVisible ? 'scale-in-center' : 'scale-out-center')}>
        {/* <Row className={`pb-5 mt-1 no-animation ` + (isVisible ? 'animate-visible-right' : 'animate-visible-right-out')}> */}
          <Card className="border-0 rounded-0">
            <Row className="pl-2 m-0">
              <div className="offset-lg-1 offset-md-0 offset-1 col-10 col-md-7 col-lg-6 align-self-center px-0 order-md-first order-last">
                <Card.Body className={`${style.textBlock} px-0`}>
                  <Card.Text className={`${style.textRight}`}>
                    {data.imageBlockDescription}
                  </Card.Text>
                </Card.Body>
              </div>

              <div className={`col-12 col-md-5 px-0 align-self-center order-first`}>
                <Card.Img className={`${style.action} rounded-0 lazyload`} alt={data.image?.title} data-src={data.image?.file?.url} />
              </div>
            </Row>
          </Card>
        </Row>
      }

    </Container >

  </Row>
)
