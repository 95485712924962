import React from 'react'
import { Col, Container, Row, Card } from 'react-bootstrap'
import 'lazysizes';
import style from './ForClientsBlock.module.css'


export default ({ data }) => (

  <Container fluid className='p-0 h-100'>    
    <Row className={`w-100 h-100 m-0 p-0`}>
      <Container>
        <h2 className={`${style.blockTitle} offset-lg-1 col-lg-10 text-center py-4 `}>
          {data.forClientsBlockTitle}
        </h2>
        <Row>
          <Col className="col-12 col-md-6 mb-3">
            <Card className="col-12 px-0 border-0 rounded-0 bg-transparent">

              <Card.Title className={`${style.blocksTitle} text-center py-4`}>
                {data.colTitleLeft}
              </Card.Title>

              {
                data.cardColLeft.map((card, key) => {
                  return <Row className={`py-3 `} key={key}>
                    <div className={`col-10 px-0`}>
                      <Card.Img className={`${style.image} rounded-0 lazyload`} alt={card.title} data-src={card.file?.url} />
                    </div>
                    <div className={`${style.body} ${style.left} col-8 col-lg-8 align-self-center px-0`}>
                      <Card.Body className={`${style.text} px-0 align-self-center`}>
                        <Card.Title className={`${style.inBlockTitle}`}>
                          {card.title}
                        </Card.Title>
                        <Card.Text className={`${style.text}`}>
                          {card.description}
                        </Card.Text>
                      </Card.Body>
                    </div>
                  </Row>
                })
              }
            </Card>
          </Col>

          <Col className="col-12 col-md-6 mb-3">
            <Card className="col-12 p-0 border-0 rounded-0 bg-transparent">

              <Card.Title className={`${style.blocksTitle} text-center py-4`}>
                {data.colTitleRight}
              </Card.Title>

              {
                data.cardColRight.map((card, key) => {
                  return <Row className={`py-3 justify-content-md-end `} key={key}>
                    <div className={`col-10 px-0`}>
                      <Card.Img className={`${style.image} rounded-0 lazyload`} alt={card.title} data-src={card.file?.url} />
                    </div>
                    <div className={`${style.body} ${style.right} col-8 col-lg-8 align-self-center px-0`}>
                      <Card.Body className={`${style.text} px-0 align-self-center`}>
                        <Card.Title className={`${style.inBlockTitle}`}>
                          {card.title}
                        </Card.Title>
                        <Card.Text className={`${style.text}`}>
                          {card.description}
                        </Card.Text>
                      </Card.Body>
                    </div>
                  </Row>
                })
              }

            </Card>
          </Col>

        </Row>

      </Container>

      <Row className={`w-100 h-100`}>
        <div className={`${style.colorBlockBrown} `}></div>
        <div className={`${style.colorBlockBlue} `}></div>
      </Row>

    </Row>
  </Container>
)
