import React from 'react'
import HubspotForm from 'react-hubspot-form'
import { Container } from 'react-bootstrap'
import style from './Form.module.css'



export default ({ isVisible }) => (

    <Container className={`${style.form} pt-5`} id='Contactform'>
        <h2 className={`${style.blockTitle} offset-lg-1 col-lg-10 text-center py-4`}>
            Contact us
        </h2>

        {isVisible && <HubspotForm
            className={`${style.form} pt-5`}
            portalId='6851631'
            formId='022a87f9-7768-473e-bc4b-c185d7c5657c'
            loading={<div>Loading...</div>}
        />}
    </Container >
)
