import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'
import logo from "../images/spheremall-logo_dark.svg";
import menu from "../images/menu.svg";
import { Container, Navbar, Nav } from 'react-bootstrap'





export default function navigation() {

  var c, currentScrollTop = 0;
  useEffect(() => {
     window.addEventListener('scroll', () => {
    let navbar = document.querySelector("." + styles.navbar);
    let a = window.scrollY;
    let b = navbar.clientHeight;

    currentScrollTop = a;

    if (c < currentScrollTop && a > b + b) {
      navbar.classList.add(styles.scrollUp);
    } else if (c > currentScrollTop && !(a <= b)) {
      navbar.classList.remove(styles.scrollUp);
    }
    c = currentScrollTop;
  });

  })

 
  return <Navbar collapseOnSelect expand="lg" sticky="top" className={`${styles.navbar} align-self-center`}>
    <Navbar.Brand href="https://spheremall.com" className={`${styles.brand}`}>
      <img
        src={logo}
        className="d-inline-block align-self-center "
        alt="Spheremall logo"
      />
    </Navbar.Brand>
    <Navbar.Toggle className={`${styles.toggle}`} aria-controls="responsive-navbar-nav">
      <span><img
        src={menu}
        alt="menu"
      ></img></span>
    </Navbar.Toggle>
    <Navbar.Collapse id="responsive-navbar-nav" className={`text-center`}>
      <Container className={`${styles.collapse} flex-nowrap px-0 text-center w-md-auto`}>
        <Nav className={`${styles.navItem} mr-auto align-self-center w-100`}>
          <Nav.Link href="https://spheremall.com/en/item/technology/" className={`${styles.link} px-lg-4 px-md-2`}>PRODUCTS</Nav.Link>
          <Nav.Link href="https://spheremall.com/en/itemslist/cases-list/" className={`${styles.link} px-lg-4 px-md-2`}>CUSTOMERS</Nav.Link>
          <Nav.Link href="https://spheremall.com/en/item/solutions/" className={`px-lg-4 px-md-2`}>INDUSTRIES</Nav.Link>
          <Nav.Link href="https://spheremall.com/en/leading/about-us/" className={`px-lg-4 px-md-2`}>PARTNERS</Nav.Link>
          <Nav.Link href="https://spheremall.com/en/leading/contact/" className={`px-lg-4 px-md-2`}>CONTACT</Nav.Link>
        </Nav>
        <Nav className={`${styles.singIn} mr-auto text-center w-100 w-lg-auto`}>
          <Nav.Link className={`${styles.linkSing} px-lg-4 px-md-2 w-100`} href="https://app.tyto.spheremall.com">sign In</Nav.Link>
        </Nav>
      </Container>
    </Navbar.Collapse>
  </Navbar>
}