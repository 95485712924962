import React from 'react'
import { Container, Row, ListGroup, Card } from 'react-bootstrap'
import style from './Contact.module.css'

function goToForm(){
  let el = document.getElementById('Contactform');
  el.scrollIntoView({ behavior: 'smooth' })  
}

export default ({ data, isVisible }) => (
  
  <Container fluid className='p-0 h-100 '>    
    <Row className={`${style.contact} mx-0 my-5 p-0`}>
      <Container>
        <Row className={`align-items-center justify-content-center py-3`} >
          <h3 className={`${style.blockTitle} order-1 mr-md-3 px-2 px-md-0 text-center`}>{data.contactDescription}</h3>
          <button onClick={goToForm} className={`${style.mainButton} order-3 order-md-2 mx-4 my-md-0 my-3 ` + (isVisible ? `heartbeat` : `heartbeat`)}>Contact</button>
          <div className={`d-flex align-items-center ml-2 order-md-3 order-2 `}>
            <img data-src={data.photo?.file?.url} alt="" className={`${style.img} px-2 lazyload`}></img>
            <div>
              <div className={`${style.name} p-1`}>{data.name}</div>
              <div className={`${style.position} p-1`}>{data.contactPosition}</div>
            </div>
          </div>
        </Row>
      </Container>

    </Row>
  </Container >
)
