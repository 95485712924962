import React, { useState, useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import { MARKS, BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styles from './mainScreen.module.css'
import ModalVideo from 'react-modal-video'
import { createGlobalStyle } from "styled-components"
import tyto from "../images/Tyto.svg"


const Bold = ({ children }) => <strong>{children}</strong>
const TextEmpty = ({ children }) => `${children}`;
const TextH = ({ children }) => <span>{children}</span>;

const optionsHead = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <TextH>{children}</TextH>
  }
}

const optionsP = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <TextEmpty>{children}</TextEmpty>
  }
}

const GlobalStyle = createGlobalStyle`
`

export default function mainScreen({ data }) {

  const [isOpen, setOpen] = useState(false);

  return < Row className='m-0 p-0'>
    <GlobalStyle />
    <Container fluid className={styles.bgMainContainer}>
      <ModalVideo channel='youtube' isOpen={isOpen} videoId="H3gOvzjiReE" onClose={() => setOpen(false)} />
      <div className={styles.bgMainImage}>
        <Container className={`${styles.componentCont} p-0`}>
          <div className={styles.tyto}>
            <div className={`${styles.tytoBG}`}></div>
            <object
              type="image/svg+xml"
              className={`${styles.tytoImg}`}
              alt="Tyto"
              data={tyto}
            />
          </div>
          <h1 className={`${styles.mainTitle} scale-in-center `}>
            {documentToReactComponents(data.mainTitle?.json, optionsHead)}
          </h1>
          <span className={`${styles.mainText} scale-in-center `}>
            {documentToReactComponents(data.description?.json, optionsP)}
          </span>
          <button className={styles.mainButton} onClick={() => setOpen(true)}>Watch</button>
        </Container>
      </div>
    </Container >
  </ Row>
}
