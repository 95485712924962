import React from 'react'
import { Container, Row, Card, CardGroup } from 'react-bootstrap'
import 'lazysizes';
import style from './RowImages.module.css'


export default ({ data, isVisible }) => (
  <Row className="m-0 p-0">
    <Container fluid className='p-0'>
      <Row className='m-0 p-0'>
        <Container>
          <h2 className={`${style.title} offset-lg-1 col-lg-10 text-center py-4  ` + (isVisible ? `scale-in-center ` : "scale-out-center")}>
            {data.title}
          </h2>

          <CardGroup className={`m-md-0 pt-0 pt-sm-3 `}>

            {
              data.cards.map(function (card, key) {
                return <Card key={key} className={`border-0 m-lg-3 m-md-1 m-2 pb-4 no-animation ` + (isVisible ? `scale-up-hor-right animation-delay-${key}` : ``)}>
                  <Card.Img className={`${style.img} lazyload`} variant="top" alt={card.title} data-src={card.file?.url} />
                  <Card.Body>
                    <Card.Title className={`${style.blocksTitle} text-center`}>
                      {card.title}
                    </Card.Title>
                    <Card.Text className={`${style.text} text-center`}>
                      {card.description}
                    </Card.Text>
                  </Card.Body>
                </Card>
              })
            }
          </CardGroup>
        </Container>

        <Row className={`${style.rowColorBg} m-0 p-0`}>
          <div className={`${style.colorBlock} no-animation ` + (isVisible ? `scale-up-hor-right ` : ``)}></div>
        </Row>     
        </Row>
    </Container>
  </Row>
)
