import React from 'react'
import '../css/base.css'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import MainScreen from '../components/mainScreen'
import IconBlock from '../components/IconBlock'
import RowImages from '../components/RowImages'
import ImageBlock from '../components/ImageBlock'
import ForClientsBlock from '../components/ForClientsBlock'
import Contact from '../components/Contact'
import Slides from '../components/Slides'
import Form from '../components/Form'
import { createGlobalStyle } from "styled-components"
import TrackVisibility from 'react-on-screen'

const channel = process.env.GATSBY_CHANNEL || 'tyto';

let channelStyles = (channel) => {
  switch (channel) {
    case 'tyto':
      return `
      :root {
        --main-color             : #34829a;
        --main-color-hover       : #679eb0;
        --background-block-main  : #f4f8fe;
        --background-block-second: #f3f3f3;
        --main-color-light       : #bcd5dd;
        --second-color           : #313131;
        --second-hover-color     : #888888;
        --font-color             : #464646;
      }
    `
    case 'heimdall':
      return `
      :root {
        --main-color             : #f51f10;
        --main-color-hover       : #08e713;
        --background-block-main  : #f51f10;
        --background-block-second: #f51f10;
        --main-color-light       : #f51f10;
        --second-color           : #f51f10;
        --second-hover-color     : #f51f10;
        --font-color             : #f51f10;
      }
    `
    default:
      return `
      :root {
        --main-color             : #34829a;
        --main-color-hover       : #679eb0;
        --background-block-main  : #f4f8fe;
        --background-block-second: #f3f3f3;
        --main-color-light       : #bcd5dd;
        --second-color           : #313131;
        --second-hover-color     : #888888;
        --font-color             : #464646;
      }
    `
  }
}

const GlobalStyle = createGlobalStyle`
    
    /* latin-ext */
    @font-face {
      font-family: 'Oswald';
      font-style: normal;
      font-weight: 200;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/oswald/v35/TK3iWkUHHAIjg752Fz8Gl-1PK62t.woff2) format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: 'Oswald';
      font-style: normal;
      font-weight: 200;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/oswald/v35/TK3iWkUHHAIjg752GT8Gl-1PKw.woff2) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    /* latin-ext */
    @font-face {
      font-family: 'Oswald';
      font-style: normal;
      font-weight: 300;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/oswald/v35/TK3iWkUHHAIjg752Fz8Gl-1PK62t.woff2) format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: 'Oswald';
      font-style: normal;
      font-weight: 300;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/oswald/v35/TK3iWkUHHAIjg752GT8Gl-1PKw.woff2) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    /* latin-ext */
    @font-face {
      font-family: 'Oswald';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/oswald/v35/TK3iWkUHHAIjg752Fz8Gl-1PK62t.woff2) format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: 'Oswald';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/oswald/v35/TK3iWkUHHAIjg752GT8Gl-1PKw.woff2) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    /* latin-ext */
    @font-face {
      font-family: 'Oswald';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/oswald/v35/TK3iWkUHHAIjg752Fz8Gl-1PK62t.woff2) format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: 'Oswald';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/oswald/v35/TK3iWkUHHAIjg752GT8Gl-1PKw.woff2) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    ${channelStyles(channel)}
`

class RootIndex extends React.Component {
  render() {
    const seoTitle = get(this.props, 'data.contentfulIndexPage.seoTitle')
    const seoDescription = get(this.props, 'data.contentfulIndexPage.seoDescription')
    const seoKeywords = get(this.props, 'data.contentfulIndexPage.seoKeywords')
    const blocks = get(this.props, 'data.contentfulIndexPage.blocks')

    return (
      <>
        <Helmet meta={[
          {
            name: `description`,
            content: seoDescription
          },
          {
            name: `keywords`,
            content: seoKeywords
          },
          {
            property: `og:title`,
            content: seoTitle
          },
          {
            property: `og:description`,
            content: seoDescription
          },
          {
            property: `og:type`,
            content: `website`
          },
          {
            name: `twitter:card`,
            content: `summary`
          },
          {
            name: `twitter:title`,
            content: seoTitle
          },
          {
            name: `twitter:description`,
            content: seoDescription
          }
        ]}>
          <html lang="en" />
          <title>{seoTitle}</title>
          <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
          <link rel="preload" href="https://fonts.gstatic.com/s/oswald/v35/TK3iWkUHHAIjg752GT8Gl-1PKw.woff2" as="font" crossorigin />
          <link rel="preload" href="https://fonts.gstatic.com/s/ptsans/v12/jizaRExUiTo99u79D0KExcOPIDU.woff2" as="font" crossorigin />
        </Helmet>
        <GlobalStyle />
        <Layout>
          {
            blocks.map((block, key) => {
              return (() => {
                switch (block.__typename) {
                  case 'ContentfulMainScreen':
                    return <TrackVisibility><MainScreen key={key} data={block}></MainScreen></TrackVisibility>
                  case 'ContentfulIconBlock':
                    return <TrackVisibility><IconBlock key={key} data={block}></IconBlock></TrackVisibility>
                  case 'ContentfulRowImages':
                    return <TrackVisibility partialVisibility><RowImages key={key} data={block}></RowImages></TrackVisibility>
                  case 'ContentfulImageBlock':
                    return <TrackVisibility><ImageBlock key={key} data={block}></ImageBlock></TrackVisibility>
                  case 'ContentfulForClientsBlock':
                    return <ForClientsBlock key={key} data={block}></ForClientsBlock>
                  case 'ContentfulContact':
                    return <TrackVisibility><Contact key={key} data={block}></Contact></TrackVisibility>
                  // case 'ContentfulSlider':
                  //   return <TrackVisibility><Slider key={key} data={block}></Slider></TrackVisibility>
                  case 'ContentfulSlides':
                    return <TrackVisibility><Slides key={key} data={block}></Slides></TrackVisibility>
                  default:
                    null
                }
              })()
            })
          }
          <TrackVisibility partialVisibility>
            <Form></Form>
          </TrackVisibility>

        </Layout>

      </>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query IndexPage {
    contentfulIndexPage {
      seoTitle
      seoDescription
      seoKeywords
      blocks {
        ... on ContentfulMainScreen {
          mainTitle: title {
            json
          }
          description {
            json
          }
        }
        ... on ContentfulIconBlock {
          description {
            internal {
              content
            }
          }
          media {
            file {
              url
            }
            title
          }
          iconBlockTitle: title {
            internal {
              content
            }
          }
          position
        }
        ... on ContentfulRowImages {
          title
          cards {
            file {
              url
            }
            title
            description
          }
        }
        ... on ContentfulImageBlock {
          image {
            file {
              url
            }
            title        
          }
          position
          imageBlockDescription: description
        }
        ... on ContentfulForClientsBlock {
          forClientsBlockTitle: title
          colTitleRight
          colTitleLeft
          cardColRight {
            description
            title
            file {
              url
            }
          }
          cardColLeft {
            title
            description
            file {
              url
            }
          }
        }
        ... on ContentfulContact {
          name
          contactDescription: description
          contactPosition: position
          photo {
            file {
              url
            }
          }
        }
        ... on ContentfulSlides {
          title
          slides {
            title
            screen {
              file {
                url
              }
            }
            list
          }
        }
      }
    }
  }
`

// ... on ContentfulSlider {
//   title
//   subTitle
//   list
//   slide {
//     file {
//       url
//     }
//   }
// }