import React, { useState } from 'react'
import { Carousel, Container, Row, ListGroup, Card } from 'react-bootstrap'
import style from './Slides.module.css'
import mac from '../images/mac.png'

export default function Slides({ data, isVisible }) {

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return <Container fluid className='p-0 h-100'>
    <Row className='m-0 p-0'>
      <Container className=''>
        <h2 className={`${style.blockTitle} offset-lg-1 col-lg-10 text-center py-4`}>{data.title}</h2>
        <Row className={`justify-content-end align-items-start p-0 m-0`} >
          <Card className={`col-12 col-md-7  px-0 border-0 bg-transparent `}>
            <img data-src={mac} alt='macbook' className={`${style.macImag} lazyload`} />
            <div className={`${style.macImgContainer}`}>
              <Carousel activeIndex={index} onSelect={handleSelect} className="p-0 m-0" >
                {data.slides?.map(function (item, key) {
                  return <Carousel.Item className="carousel-height">
                    <img key={key}
                      data-src={item.screen?.file?.url}
                      className={`${style.macImagContent} lazyload`} />
                  </Carousel.Item>
                })}
              </Carousel>
            </div>
          </Card>
          {data.slides?.map((slide, i) => {
            return <Card key={i} className={`${style.mockUpList} col-12 col-md-5 px-0 border-0 pt-0 bg-transparent ` + (i == index ? ` ${style.visible}` : '')}>
              <Card.Title className={`${style.blocksTitle} py-2 bg-transparent text-center text-md-left`}>
                {slide.title}
              </Card.Title>
              <ListGroup as="ul" variant="flush" className={`pl-4 bg-transparent `}>
                {slide.list?.map(function (listEl, i) {
                  return <ListGroup.Item key={i} as="li" className={`animation-delay-reverse-${i} no-animation ` + (isVisible ? 'scale-in-center' : '')}>{listEl}</ListGroup.Item>
                })}
              </ListGroup>
            </Card>
          })
          }
        </Row>

        {/* <Row className={`justify-content-center `}>
          <button className={`${style.mainButton} mx-4 my-3 `}>previous</button>
          <button className={`${style.mainButton} mx-4 my-3 `}>netx</button>
        </Row> */}
      </Container>



    </Row>
  </Container >
}